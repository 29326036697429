import React from 'react';
import { navigate } from 'gatsby';
import Head from '../../../components/layout/Head';
import Layout from '../../../components/layout/Layout';
import AuthWrapper from '../../../components/layout/AuthWrapper';
import ImportLearnersTable from '../../../components/organisms/import-learners-table/ImportLearnersTable';
import Button from '../../../components/atoms/button/Button';
import { IconChevronLeft } from '../../../components/icons';
import { BulkImportBackLinkNavigation } from '../../../components/organisms/bulk-import/bulkImport.style';
import { ProgressStage } from '../../../context-providers/enums/global-enums';
import { StackContainer } from '../../../components/atoms/stack/Stack.style';
import { ImportInformationText } from '../../../components/organisms/import-learners-table/importLearnerTable.style';
import { FullWidthContainer } from '../../../components/layout/Layout.style';
import ImportLearnersFeatureWrapperProps from '../../../components/layout/ImportLearnersFeatureWrapper';

const BulkImportLearnersErrorCorrectionPage = () => {
  return (
    <AuthWrapper>
      <ImportLearnersFeatureWrapperProps
        redirectTo="/list-learners"
        checkForLearnersDataHook
      >
        <Head title="BulkImportLearnersPage" />
        <Layout
          breadcrumbs={[
            { link: '/', label: 'Home' },
            { link: '/list-learners', label: 'Learners' },
            { link: '/bulk-import-learners', label: 'Bulk Import Learners' },
            {
              link: '/bulk-import-learners/error-correction',
              label: 'Error Correction ',
            },
          ]}
        >
          <BulkImportBackLinkNavigation>
            <Button
              iconLeft={<IconChevronLeft responsive />}
              onClick={() => {
                navigate('/list-learners');
              }}
              variant="naked"
            >
              Back to Learner record
            </Button>
          </BulkImportBackLinkNavigation>
          <StackContainer stackMultiplier={2}>
            <h2>Import multiple learners</h2>
            <FullWidthContainer>
              <StackContainer stackMultiplier={2}>
                <StackContainer stackMultiplier={1}>
                  <h3>Update import file</h3>
                  <ImportInformationText>
                    <p>
                      Correct the highlighted errors before completing the
                      import.
                    </p>
                  </ImportInformationText>
                </StackContainer>
                <ImportLearnersTable Stage={ProgressStage.ErrorCorrection} />
              </StackContainer>
            </FullWidthContainer>
          </StackContainer>
        </Layout>
      </ImportLearnersFeatureWrapperProps>
    </AuthWrapper>
  );
};

export default BulkImportLearnersErrorCorrectionPage;
